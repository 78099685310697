
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated */
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated */
@include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$weavix-control-center-frontend-primary: mat.define-palette(mat.$indigo-palette);
$weavix-control-center-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$weavix-control-center-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$weavix-control-center-frontend-theme: mat.define-light-theme((
  color: (
    primary: $weavix-control-center-frontend-primary,
    accent: $weavix-control-center-frontend-accent,
    warn: $weavix-control-center-frontend-warn,
  )
));

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated */
@include mat.all-legacy-component-themes($weavix-control-center-frontend-theme);
@include mat.all-component-themes($weavix-control-center-frontend-theme);

/* You can add global styles to this file, and also import other style files */
body {
    font-size: .875rem;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.scrollContainer{
  height: 100vh;
  overflow: hidden;
  margin-top: 154px;
  top: 0;
  position: fixed;
  padding-right: 25px;
  &.tab {
    margin-top: 220px;
  }
}

.scrollColumn{
  flex-grow: 1;
  position: relative;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 154px;
  &.tab {
    padding-bottom: 220px;
  }
}

.mat-row {
  height: auto;
}

td.mat-cell {
  padding: 6px !important;
}

@keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}

.loading-dots::after {
    min-width: 10px;
    display: inline-block;
    animation: dotty steps(1,end) 2s infinite;
    content: '';
}

.hide-empty {
    &:empty {
        display: none !important;
    }
}

@for $i from 1 through 5 {
    .wrap-text-#{$i} {
        display: -webkit-box;
        overflow: hidden;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
    }
}

@for $i from 1 through 1000 {
    .w-#{$i}-px {
        width: $i * 1px !important;
    }
}

.mat-snack-bar-container {
    &.success {
        background-color: green;
    }
}

.mat-snack-bar-container {
    background-color: red;
}

.mat-simple-snack-bar-content {
    color: white;
}

.mat-simple-snackbar-action {
    color: white;
}
